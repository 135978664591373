import React from 'react'
import type { FacetsType, OrFacetAvailable } from '@/types/ElasticSearch/SearchAPIResponse'
import dynamic from 'next/dynamic'
import type { onFilterChangeHandler } from './types'

const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const CloseIconBold = dynamic(import('@csc/dls/Icons/CloseIconBold'))

interface AppliedFilterBadgesProps {
  facets: FacetsType[],
  onClearFilter: onFilterChangeHandler,
  onClearAllFilters: () => void
}

const AppliedFilterBadges: React.FC<AppliedFilterBadgesProps> = ({
  facets,
  onClearFilter = () => { },
  onClearAllFilters,
}) => {
  const facetHasAppliedFilter: (f: FacetsType) => boolean = (facet) => (
    !!(facet.available as OrFacetAvailable[]).find((v) => v.selected)
  )
  const appliedFacets = facets.filter(facetHasAppliedFilter)
  if (!appliedFacets.length) {
    return null
  }

  const selectedOptions = appliedFacets.map((facet) => (
    (facet.available as OrFacetAvailable[])
      .filter((v) => v.selected).map((option) => ({ option, facet }))
  )).flat()

  return (
    <div id="appliedFilters" aria-label="Applied Filters" data-testid="Applied Filters" className="flex items-center flex-wrap gap-y-4">
      {selectedOptions.map(({ option, facet }) => (
        <button
          type="button"
          key={`${String(option.key)}_${option.value}`}
          className="background-filter mr-4 px-2 py-1.5 cursor-pointer flex items-center"
          onClick={() => onClearFilter(facet, option)}
        >
          <Text>{facet.type === 'OR' ? option.value : facet.name}</Text>
          <IconRenderer IconComponent={CloseIconBold} className="ml-4 w-2.5 h-2.5" />
        </button>
      ))}
      <Text
        link
        size="sm"
        role="listitem"
        onClick={onClearAllFilters}
        className="cursor-pointer"
      >
        Clear all
      </Text>
    </div>
  )
}

export default AppliedFilterBadges
